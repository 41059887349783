canvas {
    vertical-align: top;
}

.myParagraph:not(:first-child) {
    margin-top: 10px;
}

.myHeading {
    font-weight: bold;

    &.n1 {
        font-size: 18px;
    }

    &.n4 {
        font-size: 16px;
    }

    & + .myParagraph {
        margin-top: 0;
    }
}

.list-left {
    ol,
    ul {
        text-align: left;
    }
}

.list-2c {
    ol,
    ul {
        display: inline-block;
        -moz-column-count: 2;
        -moz-column-gap: 20px;
        -webkit-column-count: 2;
        -webkit-column-gap: 20px;
        column-count: 2;
        column-gap: 60px;
    }
}

.hor-scroll {
    overflow: auto;
    max-width: 100%;
}

.flexGroup {
    display: flex;
    flex-wrap: wrap;

    &.inline {
        display: inline-flex;
    }

    > div {
        flex-grow: 1;

        &.flexCenter {
            text-align: center;

            > div {
                display: inline-block;
            }
        }
    }
}

.optionsBar {
    border: 1px solid black;
    margin-bottom: 5px;
    cursor: pointer;
    display: flex;

    &.inline {
        display: inline-flex;

        > div {
            padding: 2px 5px;
        }
    }

    > div {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        flex-grow: 1;

        &:not(:first-child) {
            border-left: 1px solid black;
        }

        &.active {
            background-color: #1f7cff;
        }
    }
}

.iterate {
    .strikes {
        color: red;
        font-weight: bold;
    }

    .headerBox {
        border: 1px solid black;
        display: inline-block;
        padding: 5px;
        min-width: 100px;
        margin-bottom: 10px;
    }
}

#router {
    width: 100%;
    height: 100%;
}

#complicatedWires {
    table.wireChart {
        margin: auto;

        td {
            border: 1px solid black;
            text-align: center;
        }

        img {
            width: 30px;

            &.off {
                opacity: 0.1;
            }
        }

        .placeholder {
            width: 60px;
        }
    }

    .chartTitle {
        font-weight: bold;
        font-size: 18px;
    }
}

#mazes {
    .mazeMode {
        display: flex;
        justify-content: center;

        > div {
            width: 150px;
        }
    }

    .mazeSelector {
        cursor: pointer;

        .canvasWrap {
            display: inline-block;
            position: relative;

            .cover,
            .sel {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                opacity: 0.3;
            }

            .sel {
                background-color: red;

                &.active {
                    background-color: green;
                }
            }

            &:hover .cover {
                background-color: blue;
            }
        }
    }
}

#simpleWires {
    .simpleGroup {
        display: flex;
        flex-wrap: wrap;

        > div {
            display: flex;
            flex-grow: 1;

            > div {
                flex-grow: 1;
                text-align: center;
                min-width: 100px;
            }
        }
    }

    .smallOpt {
        display: inline-block;

        > div > div {
            padding: 2px 5px;
        }
    }

    .wiresPanel {
        max-width: 400px;
        margin: 0 auto;

        > div {
            height: 20px;
            border: 1px solid black;
            margin: 5px 0;
            cursor: pointer;
            text-align: center;
            font-weight: bold;
            color: red;

            > div {
                position: relative;
                top: -3px;
                display: none;
            }

            &.R {
                background-color: red;
                color: black;
            }

            &.B {
                background-color: black;
            }

            &.U {
                background-color: blue;
            }

            &.W {
                background-color: white;
            }

            &.Y {
                background-color: yellow;
            }

            &.incorrect > div {
                display: block;
            }
        }
    }
}

#thebutton {
    .buttonRender {
        border: 1px solid black;
        border-radius: 50%;
        display: inline-flex;
        width: 100px;
        height: 100px;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        cursor: default;

        &.B {
            background-color: black;
            color: white;
        }

        &.U {
            background-color: blue;
            color: white;
        }

        &.R {
            background-color: red;
            color: white;
        }

        &.W {
            background-color: white;
            color: black;
        }

        &.Y {
            background-color: yellow;
            color: black;
        }
    }

    .buttonRow button {
        margin: 0 5px;
    }

    .strip {
        border: 1px solid black;
        display: inline-block;
        padding: 2px 0;
        width: 120px;
        font-weight: bold;

        &.Yellow {
            background-color: yellow;
        }

        &.Red {
            background-color: red;
        }

        &.White {
            background-color: white;
        }

        &.Blue {
            background-color: blue;
            color: white;
        }
    }
}

#keypads {
    .symbol {
        position: relative;
        display: inline-block;

        img {
            border: 1px solid black;
        }

        div {
            text-align: center;
        }

        .cover {
            position: absolute;
            width: 100%;
            height: 100%;
            opacity: 0.75;
            &.good {
                background-color: green;
            }

            &.bad {
                background-color: red;
            }
        }
    }

    .symbolContainer > .symbol {
        margin: 2px;
        margin-bottom: 10px;
    }

    .symbolLine {
        .symbol:not(:first-child) > img {
            border-left: 0;
        }

        &:not(:first-child) img {
            border-top: 0;
        }

        .symbol {
            cursor: pointer;
        }
    }
}

#memory {
    .practice {
        text-align: center;
        display: flex;

        > div {
            max-width: 350px;
            margin: auto;
            display: flex;

            .wrap {
                border: 1px solid black;
                display: flex;
                justify-content: center;
                background-color: #6b7272;

                &:not(:first-child) {
                    border-top: 0;
                }
            }

            .display {
                background-color: #365d4d;
                color: #f0f4f7;
                width: 100px;
                height: 100px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 80px;
                border: 3px solid #242e28;
                margin: 5px;

                > div {
                    margin-top: -8px;
                }
            }

            .buttons {
                .button {
                    background-color: #b9a789;
                    color: #3b2e1c;
                    margin: 5px;
                    font-size: 50px;
                    padding: 0 10px;
                    border: 3px solid #3b2e1c;
                    cursor: pointer;
                    position: relative;

                    &.incorrect .cover {
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        background-color: red;
                        opacity: 0.5;
                    }
                }
            }

            .stagesWrap {
                position: relative;
                width: 30px;

                > div {
                    position: absolute;
                    bottom: 0;
                    width: 30px;
                    background-color: #332f35;
                    height: 143px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    > div {
                        position: relative;
                        left: -1px;
                        width: 19px;
                        background-color: #241c23;
                        flex-grow: 1;
                        margin: 6px 0;

                        &.active {
                            background-color: #c7f789;
                        }
                    }
                }
            }
        }
    }
}

#whosOnFirst {
    .module {
        max-width: 200px;
        margin: 0 auto;
        background-color: #5f5d60;
        padding: 6px;

        .display {
            border: 4px solid #10151b;
            background-color: #495c63;
            color: white;
            text-align: center;
            height: 35px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .words {
            background-color: #2c2b39;
            margin-top: 4px;
            padding: 3px;

            .wordRow {
                display: flex;
                .wordWrap {
                    display: inline-block;
                    width: 50%;
                    text-align: center;
                    padding: 3px;
                }
            }
        }
    }

    .whoButton {
        background-color: #cfbc9e;
        cursor: pointer;
        padding: 3px;
        font-weight: bold;
        height: 35px;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
            background-color: #a89881;
        }

        &.incorrect {
            background-color: #e26852;
        }

        &.correct {
            background-color: green;
            cursor: default;
        }
    }

    .whoTable {
        td {
            border: 1px solid black;
            height: 30px;
            width: 70px;
            text-align: center;
            font-weight: bold;

            &.bg {
                background-color: #cfbc9e;
            }
        }
    }

    .whoSentence {
        margin: 0 auto;

        td {
            border: 1px solid black;
            padding: 2px 5px;
        }
    }

    .practicestep2 {
        .displayWord {
            text-align: center;
            font-size: 20px;
            font-weight: bold;
        }

        .words {
            display: flex;
            flex-wrap: wrap;
            background-color: #2c2b39;
            padding: 2px;

            > div {
                min-width: 100px;
                text-align: center;
                padding: 4px;
                height: 45px;
                display: inline-block;
                flex-grow: 1;

                &.empty {
                    height: 0;
                    padding: 0;
                }

                .whoButton {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}
